import {createRouter, createWebHistory} from "vue-router";
import {authRoutes} from "@rhdhv/vue-authentication-middleware";
import {authorizationManagementRoutes} from "@rhdhv/vue-authorization-middleware";

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/',
            name: 'home',
            redirect: {
                name: 'auth-login'
            },
        },
        authRoutes,
        {
            path: '/settings',
            name: 'settings-index',
            component: () => import('@/views/settings/SettingsBaseView.vue'),
            redirect: {
                name: 'settings-roles'
            },
            children: [
                authorizationManagementRoutes
            ]
        },
        {
            path: '/projects',
            name: 'project-index',
            component: () => import('@/views/projects/IndexView.vue'),
        },
        {
            path: '/projects/:id/',
            name: 'project-details',
            component: () => import('@/views/projects/ProjectBaseView.vue'),
            children: [
                {
                    path: '',
                    name: 'project-info',
                    component: () => import('@/views/projects/DetailView.vue'),
                },
                // {
                //     path: '/projects/:id/members',
                //     name: 'project-members',
                //     component: () => import('@/views/projects/MembersView.vue'),
                // },
                {
                    path: '/projects/:id/map',
                    name: 'project-map',
                    component: () => import("@/views/projects/MapView.vue")
                },
                {
                    path: '/projects/:id/polygons',
                    name: 'project-polygons',
                    component: () => import("@/views/projects/PolygonsView.vue")
                },
                {
                    path: '/projects/:id/export-data',
                    name: 'project-export-data',
                    component: () => import("@/views/projects/ExportDataView.vue")
                },
            ]
        },
        {
            path: '/users',
            name: 'user-index',
            component: () => import('@/views/users/IndexView.vue'),
        },
        {
            path: '/polygons',
            name: 'polygon-index',
            component: () => import('@/views/polygons/IndexView.vue'),
        },
        {
            path: '/exported-data',
            name: 'exported-data-index',
            component: () => import('@/views/exported-data/IndexView.vue'),
        },
        {
            path: '/terms-and-conditions',
            name: 'terms-and-conditions',
            component: () => import('@/views/terms-and-conditions/TermsAndConditionsView.vue')
        }
    ]
})

router.beforeEach(async (to, from) => {
    const authStore = useAuthStore()
    await authStore.getPromise()

    // TODO: This weirdness should not be necessary
    let userResponse = await authStore.reAuthenticate()
    if (userResponse) {
        authStore.$patch({
            user: userResponse.user
        })
        if (!userResponse.user?.hasAcceptedTermsOfService && to.name !== 'terms-and-conditions') {
            return {name: 'terms-and-conditions'}
        }
    }

    if (to.name.startsWith('auth-')) {
        if (userResponse && userResponse.user) {
            return {name: 'project-index'}
        }
        return true
    }
    // check auth and apply login redirect
    if (!authStore.$state.user) {
        authStore.loginRedirect = to
        return {name: 'auth-login'}
    }
    return true
})

export default router
